// 
// tables.scss
//

//Table centered (Custom)
.table-centered {
    th, td {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    .action-icon {
        color: $gray-600;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0 3px;

        &:hover {
            color: $gray-700;
        }
    }
    &>:not(:first-child) {
        border: inherit;
    }
}

// Tables fluid
.table-nowrap {
    th, td {
      white-space: nowrap;
    }
}

.bgheadertable {
    background-color: #FAFBFE !important;
}

.table-margin-top {
    margin-top: 3rem;
}


// .table-striped > thead > tr:nth-of-type(2n) > * {
//     background-color: #FAFBFE;
// }


// .table-striped > tbody > tr:nth-of-type(2n) > * {
//     background-color: #FAFBFE;
// }

// .table-striped th {
//     background-color: #FAFBFE;
// }

// .bg-header-table {
//     border: 1px solid;
// }
