// 
// buttons.scss
//

.btn {
    .mdi {
        &:before {
            line-height: initial;
        }
    }
}

.btn-rounded {
    border-radius: 2em;
}

.btn-secondary {
    background-color: $secondary;
    color: $white;
    // &:hover {
    //     background-color: $secondary-dark;
    // }
}

.btn-cancel {
    background-color: $cancel;
    color: $white;
    border-color: $cancel;

    &:hover {
        color: $white;
        background-color: #5c636a;
        border-color: #565e64;
    }
}

// Button Shadows
@each $color,
$value in $theme-colors {
    .btn-#{$color} {
        @include button-shadow($value);
    }
}